import React from 'react';
import DataTables from '../../CommonDataTable/DataTableEstateMongo';
import config from '../../../../config/config';
import apiCalls from '../../../../config/apiCalls'
import ViewModal from '../../CommonModals/viewModal';
import NewUserModal from '../../CommonModals/NewUserModal';

import FormModal from '../../../Form/FormModal';
import store from '../../../App/store';
import filePath from "../../../../config/configSampleFiles";
// config file
export default class UpdateNAUPACodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageData: ''
    };
  }

  componentDidMount = () => {
    console.log("tabType", this.props.tabType)
  }

  static getDerivedStateFromProps(props, state) {
    let storeData = store.getState()
    let languageData = storeData && storeData.settingsData && storeData.settingsData.settings && storeData.settingsData.settings.languageTranslation ? storeData.settingsData.settings.languageTranslation : ""
    return { languageData: languageData };
  }

  getTableFields = () => {
    let data = [];
    return data;
  };

  getTableFields1 = () => {
    let data = [
      {
        "name": "jobName",
        "type": "text",
        "placeholder": "Job Name",
        "label": "Job Name",
        "id": "jobName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "jobName",
        "header": "Job Name",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 50,
        sortable: true,
        // "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
      },
      {
        "name": "progressStats",
        "type": "text",
        "placeholder": "Progress Stats",
        "label": "Progress Stats",
        "id": "progressStats",
        "textAlign": "Left",
        "show": true,
        "field": "progressStats",
        "header": "Progress Stats",
        "mobile": true,
        "fieldType": "wrap",
        "width": 50,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "status",
        "type": "text",
        "placeholder": "status",
        "label": "Status",
        "id": "status",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "status",
        "header": "Status",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "wrap",
        "width": 50,
        sortable: true,
        filter: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
    
      {
        "name": "created",
        "type": "text",
        "placeholder": "created",
        "label": "Start Date",
        "id": "created",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "created",
        "header": "Start Date",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Date",
        "width": 50,
        dateFormat: config.basicDateFromat,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "updated",
        "type": "text",
        "placeholder": "updated",
        "label": "End Time",
        "id": "updated",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Left",
        "show": true,
        "field": "updated",
        "header": "End Time",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "Date",
        "width": 50,
        dateFormat: config.basicDateFromat,
        sortable: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "failedCount",
        "type": "text",
        "placeholder": "Failed Count",
        "label": "Failed Count",
        "id": "failedCount",
        "textAlign": "Left",
        "show": true,
        "field": "failedCount",
        "header": "Failed Count",
        "mobile": true,
        "fieldType": "wrap",
        "width": 50,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "errorMessage",
        "type": "text",
        "placeholder": "Error Message",
        "label": "Error Message",
        "id": "errorMessage",
        "textAlign": "Left",
        "show": true,
        "field": "errorMessage",
        "header": "Error Message",
        "mobile": true,
        "fieldType": "wrap",
        "width": 50,
        sortable: false,
        filter: false,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
      {
        "name": "submittedByUserName",
        "type": "text",
        "placeholder": "submittedByUserName",
        "label": "Submitted By User Name",
        "id": "submittedByUserName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "textAlign": "Center",
        "show": true,
        "field": "submittedByUserName",
        "header": "Submitted By User Name",
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "width": 120,
        sortable: true,
        filter: true,
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }
      },
    

    
    ]
    return data;
  };

  getFormFields = () => {
    return ([
      {
        "name": "propertyName",
        "type": "text",
        "placeholder": "propertyName",
        "label": "Estate Name",
        "id": "propertyName",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
      }, {
        "name": "propertyString",
        "type": "text",
        "placeholder": "propertyString",
        "label": "Property Name String",
        "id": "propertyString",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "isAddFormHidden": false,
        "isEditFormHidden": false
      }, {
        "name": "NameMatchScore",
        "type": "text",
        "placeholder": "NameMatchScore",
        "label": "Name Score",
        "id": "NameMatchScore",
        "displayinaddForm": "true",
        "displayineditForm": "true",
        "displayinlist": "true",
        "controllerName": null,
        "show": true,
        "mobile": true,
        "displayInSettings": true,
        "fieldType": "text",
        "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" },
        "isAddFormHidden": false,
        "isEditFormHidden": false

      }]);
  }

  getFormFields1 = () => {
    return ([
      {
        "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }, {
        "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }, {
        "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "show": true, "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" }, "isAddFormHidden": false, "isEditFormHidden": false
      }]);
  }

  submit = async (item) => {

    this.setState({ isOpenFormModal: true })
    await this.formModalRef.getRowData(item, 'edit');

    console.log("Submit Button in sode          ")
  }
  getMobileTableFields = () => {
    let data = [{ "textAlign": "center", "width": 47, "field": "", "fieldType": "multiple", "header": "", "selectionMode": "multiple", "show": true, "mobile": true, "displayInSettings": true }, { "textAlign": "center", "width": 47, "field": "Sno", "header": "SNo", "filter": false, "sortable": false, "placeholder": "Search", "show": true, "mobile": true, "displayInSettings": true }, { "name": "propertyName", "type": "text", "placeholder": "propertyName", "label": "Matching Property Name", "id": "propertyName", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyName", "header": "Matching Property Name", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "propertyString", "type": "text", "placeholder": "propertyString", "label": "Property Name String", "id": "propertyString", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "propertyString", "header": "Property Name String", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }, { "name": "NameMatchScore", "type": "text", "placeholder": "NameMatchScore", "label": "Name Score", "id": "NameMatchScore", "displayinaddForm": "true", "displayineditForm": "true", "displayinlist": "true", "controllerName": null, "textAlign": "Center", "show": true, "field": "NameMatchScore", "header": "Name Score", "mobile": true, "displayInSettings": true, "fieldType": "text", "style": { "color": "#0e4768", "cursor": "pointer", "textTransform": "capitalize" } }]

    return data;
  };
  closeFormModal = async () => {
    this.setState({
      isOpenFormModal: false,
    })
  }

  cancelReset = async (type) => {
    this.setState({
      openNewUserModal: false
    });
    window.location.reload();

  
  };
  render() {
    return (
      <span>
        <DataTables
          onRef={ref => (this.dataTableRef = ref)}
          addRequired={false}
          editRequired={false}
          deleteRequired={false}
          viewRequired={false}
          exportRequired={false}
          sample={false}
          getTableFields={this.getTableFields}
          getTableFields1={this.getTableFields1}
          formFields={this.getFormFields}
          printRequried={false}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': true, "multiple": true, },
            ]
          },
         ]}
          settingsRequired={true}
          filterRequired={true}
          gridRequried={true}
          exportToCsv={true}
          tabType={this.props.tabType}
          sampleFilePath={filePath.scorings}

          globalSearch={'Name String'}
          type='Update NAUPA Code'
          routeTo={apiCalls.getBucketUpdateLogs}
          displayViewOfForm='screen'
          apiResponseKey={"log_ns_bucket_manual_update"}
          apiUrl={apiCalls.getBucketUpdateLogs}

        />
      </span>
    );
  }
}