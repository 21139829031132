import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import UpdateNAUPACode from './components/UpdateNAUPACodes';

const NAUPACode = (props) => (
  < div >
    <UpdateNAUPACode
      {...props}
    />
  </div >
);

NAUPACode.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(NAUPACode);
