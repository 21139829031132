import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import BatchStatus from './components/UpdateBucketNames';

const estateMongo = (props) => (
  < div >
    <BatchStatus
      {...props}
    />
  </div >
);

estateMongo.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(estateMongo);
